<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="page-card">
        <div class="row align-items-center mb-3 border-bottom">
          <div class="col-md-6 d-flex align-items-center gap-4">
            <h2 class="page-title">OVERVIEW</h2>
          </div>
          <div class="col-md-6 text-end">
            <select class="form-select border-0 w-auto ms-auto font-14 fw-light" v-on:change="onUserActivity(type)"
              v-model="type" aria-label="Default select example">
              <option selected value="WEEKLY">Last 7 Days (Weekly)</option>
              <option value="DAILY">Daily</option>
              <option value="MONTHLY">Monthly</option>
              <option value="YEARLY">Yearly</option>
            </select>
          </div>
        </div>
        <div class="card">
          <div class="card-header" v-if="type == 'WEEKLY'">
            <VueDatePicker class="form-control" @closed="onUserActivity('WEEKLY')" v-model="week" week-picker
              :enable-time-picker="false"></VueDatePicker>
          </div>
          <div class="card-header" v-if="type == 'MONTHLY'">
            <VueDatePicker class="form-control" month-picker @closed="onUserActivity('MONTHLY')" v-model="month"
              :enable-time-picker="false"></VueDatePicker>
          </div>
          <div class="card-header" v-if="type == 'YEARLY'">
            <VueDatePicker class="form-control" year-picker @closed="onUserActivity('YEARLY')" v-model="year"
              :enable-time-picker="false"></VueDatePicker>
          </div>
          <div class="card-header" v-if="type == 'DAILY'">
            <VueDatePicker class="form-control" v-model="today_date" @closed="onUserActivity('DAILY')"
              :enable-time-picker="false">
            </VueDatePicker>
          </div>
          <div class="card-body" id="graph-container">
            <canvas id="userActivity" class="chartjs-render-monitor"></canvas>
          </div>
        </div>
        <br>
        <h2 class="page-title mb-3">Recent</h2>
        <div class="table-wrap">
          <table class="table table-style">
            <thead>
              <tr>
                <th scope="col"> ID</th>
                <th scope="col"> Users </th>
                <th scope="col" class="text-center"> Scan </th>
                <th scope="col" class="text-center"> App </th>
                <th scope="col" class="text-center"> Date | Time </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item) in recentScanList" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item?.user?.full_name }}</td>
                <td class="text-center" v-if="item.scan_status == 'COMPLETE'"><span style="color: #2B8000;"> {{
                  $helperService.getTitleCaseFormate(item?.scan_status) }}</span></td>
                <td class="text-center" v-if="item.scan_status == 'FAILED'"><span style="color: #F2233B;"> {{
                  $helperService.getTitleCaseFormate(item?.scan_status) }}</span></td>
                <td class="text-center" v-if="item.scan_status == 'NOT_COMPLETE'"><span style="color: #F2233B;"> {{
                  $helperService.getTitleCaseFormate(item?.scan_status) }}</span></td>
                <td class="text-center" v-if="item.scan_status == 'STOPPED'"><span style="color: #F2233B;"> {{
                  $helperService.getTitleCaseFormate(item?.scan_status) }}</span></td>
                <td class="text-center">{{ $helperService.getTitleCaseFormate(item?.platform) }}</td>
                <td class="text-center">{{ $helperService.getDateTimeWithoutUtc(item.created_at) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="page-card mb-3">
        <div class="row align-items-center mb-3 border-bottom">
          <div class="col-md-6 d-flex align-items-center gap-4">
            <h2 class="page-title">REPORT</h2>
          </div>
          <div class="col-md-6 text-end">
            <select class="form-select border-0 w-auto ms-auto font-14 fw-light" v-on:change="onReportActivity(report_type)"
              v-model="report_type" aria-label="Default select example">
              <option selected value="WEEKLY">Last 7 Days (Weekly)</option>
              <option value="DAILY">Daily</option>
              <option value="MONTHLY">Monthly</option>
              <option value="YEARLY">Yearly</option>
            </select>
          </div>
        </div>
        <div class="card-body" id="graph-container-report">
            <canvas id="reportActivity" class="chartjs-render-monitor"></canvas>
          </div>
      </div>

      <div class="page-card mb-3">
        <div class="row align-items-center mb-3 border-bottom">
          <div class="col-md-6 d-flex align-items-center gap-4">
            <h2 class="page-title">usage</h2>
          </div>
          <div class="col-md-6 text-end">
            <select class="form-select border-0 w-auto ms-auto font-14 fw-light" v-on:change="onUsageActivity(usage_type)"
              v-model="usage_type" aria-label="Default select example">
              <option selected value="WEEKLY">Last 7 Days (Weekly)</option>
              <option value="DAILY">Daily</option>
              <option value="MONTHLY">Monthly</option>
              <option value="YEARLY">Yearly</option>
            </select>
          </div>
        </div>
        <div class="card card-body mb-3">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <p class="mb-0 text-secondary">Total Apps </p>
            <div class="input-group flex-nowrap usage-count highest">
              <span class="input-group-text" id="addon-wrapping"><i class="bi bi-caret-up-fill"></i></span>
              <input type="text" class="form-control" value="80%">
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class=" fw-semibold font-18">{{ total_apps_scan }} | <span class="text-danger"> {{ total_apps_failed_scan }} <sup class=" fw-normal"> scan
                  failed</sup></span></span>
            <a  href="javascript:void(0)" @click="redirectToAppList" >Details <i class="bi bi-chevron-right"></i></a>
          </div>
        </div>
        <div class="card card-body mb-3">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <p class="mb-0 text-secondary">Total kiosks</p>
            <div class="input-group flex-nowrap usage-count low">
              <span class="input-group-text" id="addon-wrapping"><i class="bi bi-caret-down-fill"></i></span>
              <input type="text" class="form-control" value="20%">
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class=" fw-semibold font-18">{{ total_kiosk_scan }} | <span class="text-danger">{{ total_kiosk_failed_scan }} <sup class=" fw-normal"> scan
                  failed</sup></span></span>
            <a href="javascript:void(0)"  @click="redirectToAppList">Details <i class="bi bi-chevron-right"></i></a>
          </div>
        </div>
        <div class="card card-body mb-3">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <p class="mb-0 text-secondary">Total Android</p>
            <div class="input-group flex-nowrap usage-count high">
              <span class="input-group-text" id="addon-wrapping"><i class="bi bi-caret-up-fill"></i></span>
              <input type="text" class="form-control" value="70%">
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class=" fw-semibold font-18">{{ android_scan }} | <span class="text-danger">{{ android_failed_scan }}<sup class=" fw-normal"> scan
                  failed</sup></span></span>
            <a  href="javascript:void(0)"  @click="redirectToAppList">Details <i class="bi bi-chevron-right"></i></a>
          </div>
        </div>
        <div class="card card-body mb-3">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <p class="mb-0 text-secondary">Total Ios</p>
            <div class="input-group flex-nowrap usage-count high">
              <span class="input-group-text" id="addon-wrapping"><i class="bi bi-caret-up-fill"></i></span>
              <input type="text" class="form-control" value="70%">
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class=" fw-semibold font-18">{{ ios_scan }} | <span class="text-danger">{{ ios_failed_scan }} <sup class=" fw-normal"> scan
                  failed</sup></span></span>
            <a  href="javascript:void(0)"  @click="redirectToAppList">Details <i class="bi bi-chevron-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Chart from 'chart.js/auto';
Chart.register("bar");
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref } from 'vue';
export default {
  name: "DashBoard",
  components: { VueDatePicker },
  data() {
    return {
      type: "WEEKLY",
      week: [],
      year: ref(new Date().getFullYear()),
      today_date: ref(new Date()),
      month: ref({
        month: new Date().getMonth(),
        year: new Date().getFullYear()
      }),
      report_type: "WEEKLY",
      report_week: [],
      report_year: ref(new Date().getFullYear()),
      report_today_date: ref(new Date()),
      report_month: ref({
        month: new Date().getMonth(),
        year: new Date().getFullYear()
      }),
      usage_type: "WEEKLY",
      usage_week: [],
      usage_year: ref(new Date().getFullYear()),
      usage_today_date: ref(new Date()),
      usage_month: ref({
        month: new Date().getMonth(),
        year: new Date().getFullYear()
      }),
      reportFilterObj: {
        from_date: "",
        to_date: "",
      },
      usageFilterObj: {
        from_date: "",
        to_date: "",
      },
      filterObj: {
        from_date: "",
        to_date: "",
      },
      android_left_title: [],
      ios_left_title: [],
      pi_internal_left_title: [],
      pi_external_left_title: [],
      total_scan_left_title: [],
      common_bottom_title: [],
      report_left_title: [],
      report_bottom_title: [],
      complete_scan: "",
      failed_result: "",
      userActivity: "userActivity",
      reportActivity: "reportActivity",
      shared_result: 0,
      recentScanList: [],
      total_kiosk_scan:null,
      total_kiosk_failed_scan:null,
      total_apps_scan:null,
      total_apps_failed_scan:null,
      android_failed_scan:null,
      ios_failed_scan:null,
      android_scan:null,
      ios_scan:null,
      total_mobile_failed_scan:null,
    };
  },
  mounted() {
    let date_today = new Date();
    let first_day_of_the_week = new Date(date_today.setDate(date_today.getDate()
      - date_today.getDay()));

    let last_day_of_the_week = new Date(date_today.setDate(date_today.getDate()
      - date_today.getDay() + 6));

    this.week.push(first_day_of_the_week, last_day_of_the_week);
    this.filterObj.from_date = first_day_of_the_week;
    this.filterObj.to_date = last_day_of_the_week;
    this.report_week.push(first_day_of_the_week, last_day_of_the_week);
    this.reportFilterObj.from_date = first_day_of_the_week;
    this.reportFilterObj.to_date = last_day_of_the_week;
    this.usage_week.push(first_day_of_the_week, last_day_of_the_week);
    this.usageFilterObj.from_date = first_day_of_the_week;
    this.usageFilterObj.to_date = last_day_of_the_week;
    this.onUserActivity(this.type);
    this.onReportActivity(this.report_type);
    this.onUsageActivity(this.usage_type);
    this.onRecentScanList();
  },
  methods: {
    redirectToAppList(){
      return this.$router.push("/apps");
    },
    clearfilterObj() {
      this.filterObj.from_date = "";
      this.filterObj.to_date = "";
    },
    clearReportFilterObj() {
      this.reportFilterObj.from_date = "";
      this.reportFilterObj.to_date = "";
    },
    clearUsageFilterObj() {
      this.usageFilterObj.from_date = "";
      this.usageFilterObj.to_date = "";
    },
    onUserActivity(type) {
      this.type = type;
      if (this.type == "WEEKLY") {
        this.filterObj.from_date = moment(String(this.week[0])).format(
          "Y-MM-DD"
        );
        this.filterObj.to_date = moment(String(this.week[1])).format(
          "Y-MM-DD"
        );

        console.log(this.filterObj);
      }
      if (this.type == "DAILY") {
        this.filterObj.from_date = moment(String(this.today_date)).format(
          "Y-MM-DD"
        );
        this.filterObj.to_date = moment(String(this.today_date)).format(
          "Y-MM-DD"
        );
        console.log(this.filterObj);
      }

      if (this.type == "MONTHLY") {
        let month = this.getNumberOfMonths(this.month.month);
        console.log("from Monthly :", month);
        let year = this.month.year;
        let date = new Date(year, month, 1), y = date.getFullYear(), m = date.getMonth();
        let firstDay = new Date(y, m, 1);
        let lastDay = new Date(y, m + 1, 0);
        console.log(firstDay, lastDay);
        this.filterObj.from_date = moment(firstDay).format("Y-MM-DD");
        this.filterObj.to_date = moment(lastDay).format("Y-MM-DD");
        console.log(this.filterObj, this.month);

      }

      if (this.type == "YEARLY") {

        this.filterObj.year = moment(String(this.year)).format("Y");
        const firstDay = new Date(this.filterObj.year, 0, 1);
        const lastDay = new Date(this.filterObj.year, 11, 31);
        this.filterObj.from_date = moment(firstDay).format("Y-MM-DD");
        this.filterObj.to_date = moment(lastDay).format("Y-MM-DD");
        console.log(this.filterObj, this.year);

      }
      window.$("#userActivity").remove();
      window.$('#graph-container').append('<canvas id="userActivity" class="chartjs-render-monitor"><canvas>');
      this.loader = true;
      this.$api
        .webRequest({
          _method: "GET",
          _action: "dashboard/" + this.type + "/graph",
          _body: this.filterObj
        })
        .then(res => {
          this.loader = false;
          if (res.pi_internals && res.pi_externals && res.android_graph && res.ios_graph && res.total_scans) {
            var obj = {
              pi_internals_scan_title: res.internals_scan_title,
              pi_internals_color_code: res.pi_internals_color_code,
              pi_internal_counts: res.pi_internal_counts,
              pi_external_scan_title: res.external_scan_title,
              pi_external_counts: res.pi_external_counts,
              pi_externals_color_code: res.pi_externals_color_code,
              android_color_code: res.android_color_code,
              android_scan_title: res.android_scan_title,
              ios_color_code: res.ios_color_code,
              ios_scan_title: res.ios_scan_title,
              android_counts: res.android_counts,
              ios_counts: res.ios_counts,
              total_color_code: res.total_color_code,
              total_scan_title: res.total_scan_title,
              total_counts: res.total_counts,
            }
            this.userActivityChart(res.pi_internals, res.pi_externals, res.android_graph,res.ios_graph, res.total_scans, obj);
          } else {
            var temObj = {
              pi_internals_scan_title: "",
              pi_internals_color_code: "",
              pi_internal_counts: "",
              pi_external_scan_title: "",
              pi_externals_color_code: "",
              android_color_code: "",
              android_scan_title: "",
              ios_color_code: "",
              ios_scan_title: "",
              android_counts: "",
              ios_counts: "",
              total_color_code: "",
              total_scan_title: "",
              total_counts: "",
            }
            var pi_internals = [];
            var pi_externals = [];
            var android_graph = [];
            var ios_graph = [];
            var total_scans = [];
            this.userActivityChart(pi_internals, pi_externals, android_graph,ios_graph, total_scans, temObj)
          }
          this.clearfilterObj();
        })
        .catch((e) => {
          // this.$toast.error(e.message, { position: "top-right" });
          console.error(e.message);
        });
    },
    userActivityChart(pi_internal, pi_external, android_graph,ios_graph, total_count, obj) {

      console.log("data from graph mode:", pi_internal, pi_external, android_graph,ios_graph, total_count, obj);

      this.android_left_title = [];
      this.ios_left_title = [];
      this.pi_internal_left_title = [];
      this.pi_external_left_title = [];
      this.total_scan_left_title = [];
      this.common_bottom_title = [];

      for (var i = 0; i < pi_internal.length; i++) {
        if (pi_internal[i].pi_internal) {
          this.pi_internal_left_title.push(pi_internal[i].pi_internal);
        }
        this.common_bottom_title.push(pi_internal[i].graph_date);
      }

      for (var j = 0; j < pi_external.length; j++) {
        if (pi_external[j].pi_external) {
          this.pi_external_left_title.push(pi_external[j].pi_external);
        }
      }

      for (var k = 0; k < android_graph.length; k++) {
        if (android_graph[k].android) {
          this.android_left_title.push(android_graph[k].android);
        }
      }

      for (var l = 0; l < total_count.length; l++) {
        if (total_count[l].total_scan) {
          this.total_scan_left_title.push(total_count[l].total_scan);
        }
      }

      for (var m = 0; m < ios_graph.length; m++) {
        if (ios_graph[m].ios) {
          this.ios_left_title.push(ios_graph[m].ios);
        }
      }

      

      console.log("title from pie :", this.common_bottom_title);
      if (document.getElementById(this.userActivity)) {
        var ctx = document.getElementById(this.userActivity).getContext("2d");
        // var $this = this;
        this.userChart = new Chart(ctx, {
          type: "bar",
          data: {
            datasets: [
              {
                label: obj.pi_internals_scan_title + " - " + obj.pi_internal_counts,
                data: this.pi_internal_left_title,
                fill: false,
                backgroundColor: [obj.pi_internals_color_code],
                borderColor: obj.pi_internals_color_code,
                hoverOffset: 4,
                tension: 0.1
              },
              {
                label: obj.pi_external_scan_title + " - " + obj.pi_external_counts,
                data: this.pi_external_left_title,
                fill: false,
                backgroundColor: [obj.pi_externals_color_code],
                borderColor: obj.pi_externals_color_code,
                hoverOffset: 4,
                tension: 0.1,
                type: "bar"
              },
              {
                label: obj.android_scan_title + " - " + obj.android_counts,
                data: this.android_left_title,
                fill: false,
                backgroundColor: [obj.android_color_code],
                borderColor: obj.android_color_code,
                hoverOffset: 4,
                tension: 0.1,
                type: "bar"
              },
              {
                label: obj.ios_scan_title + " - " + obj.ios_counts,
                data: this.ios_left_title,
                fill: false,
                backgroundColor: [obj.ios_color_code],
                borderColor: obj.ios_color_code,
                hoverOffset: 4,
                tension: 0.1,
                type: "bar"
              },
              {
                label: obj.total_scan_title + " - " + obj.total_counts,
                data: this.total_scan_left_title,
                fill: false,
                backgroundColor: [obj.total_color_code],
                borderColor: obj.total_color_code,
                hoverOffset: 4,
                tension: 0.1,
                type: "bar"
              },
            ],
            labels: this.common_bottom_title
          },
          options: {
            tooltips: {
              callbacks: {
                title: function (t, d) {
                  let title = d.datasets[t[0].datasetIndex].label;
                  return title;
                },
                label: function (t, d) {
                  // let title = d.datasets[t.datasetIndex].label;
                  let label = d.labels[t.index];
                  let value = d.datasets[t.datasetIndex].data[t.index];
                  return label + ": " + value + "%";
                }
              }
            }
          }
        });
      }
    },
    getNumberOfMonths(value) {

      return value;
    },

    onRecentScanList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "dashboard/recent-data",
        }).then((res) => {
          this.recentScanList = res.list;
        })
        .catch((e) => {
          // this.$toast.error(e.message, {
          //   position: "top-right"
          // });
          console.error(e.message);
        });
    },
    onReportActivity(type) {
      this.report_type = type;
      if (this.report_type == "WEEKLY") {
        this.reportFilterObj.from_date = moment(String(this.week[0])).format(
          "Y-MM-DD"
        );
        this.reportFilterObj.to_date = moment(String(this.week[1])).format(
          "Y-MM-DD"
        );

        console.log(this.reportFilterObj);
      }
      if (this.report_type == "DAILY") {
        this.reportFilterObj.from_date = moment(String(this.today_date)).format(
          "Y-MM-DD"
        );
        this.reportFilterObj.to_date = moment(String(this.today_date)).format(
          "Y-MM-DD"
        );
        console.log(this.reportFilterObj);
      }

      if (this.report_type == "MONTHLY") {
        let month = this.getNumberOfMonths(this.month.month);
        console.log("from Monthly :", month);
        let year = this.month.year;
        let date = new Date(year, month, 1), y = date.getFullYear(), m = date.getMonth();
        let firstDay = new Date(y, m, 1);
        let lastDay = new Date(y, m + 1, 0);
        console.log(firstDay, lastDay);
        this.reportFilterObj.from_date = moment(firstDay).format("Y-MM-DD");
        this.reportFilterObj.to_date = moment(lastDay).format("Y-MM-DD");
        console.log(this.reportFilterObj, this.month);

      }

      if (this.report_type == "YEARLY") {

        this.reportFilterObj.year = moment(String(this.year)).format("Y");
        const firstDay = new Date(this.reportFilterObj.year, 0, 1);
        const lastDay = new Date(this.reportFilterObj.year, 11, 31);
        this.reportFilterObj.from_date = moment(firstDay).format("Y-MM-DD");
        this.reportFilterObj.to_date = moment(lastDay).format("Y-MM-DD");
        console.log(this.reportFilterObj, this.year);

      }
      window.$("#reportActivity").remove();
      window.$('#graph-container-report').append('<canvas id="reportActivity" class="chartjs-render-monitor"><canvas>');
      this.loader = true;
      this.$api
        .webRequest({
          _method: "GET",
          _action: "dashboard/" + this.report_type + "/graph/report",
          _body: this.reportFilterObj
        })
        .then(res => {
          this.loader = false;
          if (res.total_scans) {
            var obj = {
              total_color_code: res.total_color_code,
              total_scan_title: res.total_scan_title,
              total_counts: res.total_counts,
            }
            this.reportActivityChart(res.total_scans, obj);
          } else {
            var temObj = {
              total_color_code: "",
              total_scan_title: "",
              total_counts: "",
            }

            var total_scans = [];
            this.userActivityChart(total_scans, temObj)
          }
          this.clearReportFilterObj();
        })
        .catch((e) => {
          // this.$toast.error(e.message, { position: "top-right" });
          
          console.error(e.message);
        });
    },
    reportActivityChart(total_count, obj) {
      console.log("data from graph mode:", total_count, obj);
      this.report_bottom_title = [];
      this.report_left_title = [];

      for (var i = 0; i < total_count.length; i++) {
        if (total_count[i].total_scan) {
          this.report_left_title.push(total_count[i].total_scan);
        }
        this.report_bottom_title.push(total_count[i].graph_date);
      }


      console.log("title from pie :", this.report_bottom_title);
      if (document.getElementById(this.reportActivity)) {
        var ctx = document.getElementById(this.reportActivity).getContext("2d");
        // var $this = this;
        this.reportChart = new Chart(ctx, {
          type: "bar",
          data: {
            datasets: [
              {
                label: obj.total_scan_title,
                data: this.report_left_title,
                fill: false,
                backgroundColor: [obj.total_color_code],
                borderColor: obj.total_color_code,
                hoverOffset: 4,
                tension: 0.1
              }
            ],
            labels: this.report_bottom_title
          },
          options: {
            tooltips: {
              callbacks: {
                title: function (t, d) {
                  let title = d.datasets[t[0].datasetIndex].label;
                  return title;
                },
                label: function (t, d) {
                  // let title = d.datasets[t.datasetIndex].label;
                  let label = d.labels[t.index];
                  let value = d.datasets[t.datasetIndex].data[t.index];
                  return label + ": " + value + "%";
                }
              }
            }
          }
        });
      }
    },
    onUsageActivity(type) {
      this.usage_type = type;
      if (this.usage_type == "WEEKLY") {
        this.usageFilterObj.from_date = moment(String(this.week[0])).format(
          "Y-MM-DD"
        );
        this.usageFilterObj.to_date = moment(String(this.week[1])).format(
          "Y-MM-DD"
        );

        console.log(this.usageFilterObj);
      }
      if (this.usage_type == "DAILY") {
        this.usageFilterObj.from_date = moment(String(this.today_date)).format(
          "Y-MM-DD"
        );
        this.usageFilterObj.to_date = moment(String(this.today_date)).format(
          "Y-MM-DD"
        );
        console.log(this.usageFilterObj);
      }

      if (this.usage_type == "MONTHLY") {
        let month = this.getNumberOfMonths(this.month.month);
        console.log("from Monthly :", month);
        let year = this.month.year;
        let date = new Date(year, month, 1), y = date.getFullYear(), m = date.getMonth();
        let firstDay = new Date(y, m, 1);
        let lastDay = new Date(y, m + 1, 0);
        console.log(firstDay, lastDay);
        this.usageFilterObj.from_date = moment(firstDay).format("Y-MM-DD");
        this.usageFilterObj.to_date = moment(lastDay).format("Y-MM-DD");
        console.log(this.usageFilterObj, this.month);

      }

      if (this.usage_type == "YEARLY") {

        this.usageFilterObj.year = moment(String(this.year)).format("Y");
        const firstDay = new Date(this.usageFilterObj.year, 0, 1);
        const lastDay = new Date(this.usageFilterObj.year, 11, 31);
        this.usageFilterObj.from_date = moment(firstDay).format("Y-MM-DD");
        this.usageFilterObj.to_date = moment(lastDay).format("Y-MM-DD");
        console.log(this.usageFilterObj, this.year);

      }
     
      this.loader = true;
      this.$api
        .webRequest({
          _method: "GET",
          _action: "dashboard/" + this.usage_type + "/graph/usage",
          _body: this.usageFilterObj
        })
        .then(res => {
          this.loader = false;
         console.log(res);
         this.total_apps_scan = res.total_apps_scan;
         this.ios_scan = res.ios_scan;
         this.android_scan = res.android_scan;
         this.android_failed_scan = res.android_failed_scan;
         this.total_kiosk_scan = res.total_kiosk_scan;
         this.total_kiosk_failed_scan = res.total_kiosk_failed_scan;
         this.ios_failed_scan = res.ios_failed_scan;
         this.total_apps_failed_scan = res.total_apps_failed_scan;
          this.clearUsageFilterObj();
        })
        .catch((e) => {
          // this.$toast.error(e.message, { position: "top-right" });
          
          console.error(e.message);
        });
    },
  },
};
</script>
