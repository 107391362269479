<template>
  <div class="container mt-4">
    <!-- Search Bar -->

    <!-- Categories -->
    <div class="faq-categories mb-4">
      <button v-for="category in categories" :key="category.id" class="btn btn-outline-primary me-2"
        @click="selectCategory(category.id)" :class="{ active: selectedCategory === category.id }">
        {{ category.name }}
      </button>
    </div>
  </div>

  <div class="page-card">
    <div class="row">
      <!-- FAQ Categories Section on the Left -->
      <div class="col-md-12">
        <!-- FAQ Categories Header -->
        <div class="row align-items-center mb-3">
          <div class="col-md-6 d-flex align-items-center justify-content-between">
            <h2 class="page-title">FAQ Categories</h2>
            <a href="javascript:void(0)" @click="openAddCategoryModal"
              class="btn btn-link d-flex align-items-center ms-4 AddCategory_btn">
              Add Category <i class="bi bi-plus-circle-fill fs-5 ms-2"></i>
            </a>
          </div>
          <div class="col-md-6">
            <!-- <div
              class="d-flex justify-content-end font-12 text-uppercase gap-2 align-items-center">
              <span>show</span>
              <div class="bg-light-brand px-2 py-1 rounded">
                {{ itemsPerPage }}
              </div>
              <span>entries</span>
            </div> -->
          </div>
        </div>

        <!-- FAQ Categories Table -->
        <div class="table-wrap">
          <table class="table table-style">
            <thead>
              <tr>
                <th scope="col">
                  <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'id')" />
                  ID
                </th>
                <th scope="col">
                  <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'name')" />
                  Name
                </th>
                <th scope="col">Usage</th>
                <th scope="col">Order</th>
                <th scope="col" class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loader">
                <td colspan="5" class="text-center">
                  <div class="spinner-border spinner-color" role="status"></div>
                </td>
              </tr>
              <tr v-else v-for="category in faqCategories" :key="category.id">
                <td>{{ category.id }}</td>
                <td>{{ category.name }}</td>
                <td>
                  <span class="px-4 py-1 rounded" :class="{
                    'bg-success text-white': category.usage === 'in use',
                    'bg-light': category.usage === 'not used',
                  }">
                    {{ category.usage }}
                  </span>
                </td>
                <td>{{ category.order }}</td>
                <td class="text-center">
                  <div class="action-ele">
                    <a href="javascript:void(0)" @click="cloneCategory(category.id)" title="clone">
                      <img src="/images/icon-view.svg" width="22" alt="clone" />
                    </a>
                    <a href="javascript:void(0)" @click="editCategory(category.id)" title="Edit">
                      <img src="/images/icon-edit.svg" width="22" alt="Edit" />
                    </a>
                    <a href="javascript:void(0)" @click="archiveCategory(category.id, category.archive)"
                      :class="{ disabled: category.usage === 'not used' }" title="Archive">
                      <img src="/images/icon-archive.svg" width="20" :alt="category.archive === 'not used'
                        ? 'Marked as in use'
                        : 'Marked as not used'
                        " />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-between mr-2 mb-1">
    <!-- FAQ Intro Section -->
    <div class="col-md-5 ">
      <div class="intro">
        <div class="faq-intro">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h3 class="page-title">FAQ PAGE INTRO</h3>
            <a href="javascript:void(0)" @click="editFaqIntro" title="Edit">
              <img src="/images/icon-edit.svg" width="24" alt="Edit" />
            </a>
          </div>

          <div class="align-items-center">
            <strong class="faq-intro-title1">{{ faqIntro.title }}</strong>
            <p class="faq-intro-description">
              {{ formatDate(faqIntro.updated_at) }} <br />
              {{ faqIntro.description }}
            </p>
          </div>
        </div>

        <!-- FAQ Items Table (SET FAQ ITEM) -->
        <div class="col-md-12 page-card">
          <div class="row align-items-center mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="page-title mb-0 text-purple fw-bold">SET FAQ ITEM</h5>
              <button @click="openNewFaqModal" class="addNew_btn btn btn-link ms-3 d-flex align-items-center">
                <span>ADD NEW</span>
                <i class="bi bi-plus-circle-fill ms-2"></i>
              </button>
            </div>
          </div>

          <!-- FAQ Items Table -->
          <div class="table-wrap">
            <table class="table table-style">
              <thead>
                <tr>
                  <th scope="col">
                    <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'id')" />
                    ID
                  </th>
                  <th scope="col">
                    <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'name')" />
                    Name
                  </th>
                  <th scope="col" class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loader">
                  <td colspan="5" class="text-center">
                    <div class="spinner-border spinner-color" role="status"></div>
                  </td>
                </tr>
                <tr v-else v-for="item in faqItems" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ item.question }}</td>
                  <td class="text-center">
                    <div class="action-ele">
                      <label class="switch">
                        <input type="checkbox" :checked="item.status === 1" @change="toggleFaqStatus(item)" />
                        <span class="slider round"></span>
                      </label>
                      <a href="javascript:void(0)" @click="editFaq(item.id)" title="Edit">
                        <img src="/images/icon-edit.svg" width="22" alt="Edit" />
                      </a>
                      <a href="javascript:void(0)" @click="deleteFaq(item.id)" title="Delete">
                        <img src="/images/icon-delete.svg" width="22" alt="Delete" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- FAQ Form Section (Create / Edit) -->
    <div class="col-md-7">
      <div class="page-card">
        <div>
          <div>
            <h5 class="modal-title">
              {{
                isEditingFaqIntro
                  ? "Edit FAQ Intro"
                  : isEditingFaq
                    ? "Edit FAQ"
                    : "Add New FAQ"
              }}
            </h5>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitFaqForm">
              <!-- If editing FAQ Intro, show these fields -->
              <div v-if="isEditingFaqIntro">
                <div class="mb-3">
                  <label for="introQuestion" class="form-label">Name</label>
                  <input type="text" class="form-control" id="introQuestion" v-model="faqIntroForm.title" required />
                </div>
                <div class="mb-3">
                  <label for="introDescription" class="form-label">Description</label>
                  <textarea class="form-control" id="introDescription" v-model="faqIntroForm.description" rows="3"
                    required></textarea>
                </div>
                <div class="mb-3">
                  <label for="introDate" class="form-label">Date</label>
                  <input type="date" class="form-control" id="introDate" v-model="faqIntroForm.date" required />
                </div>
              </div>
              <!-- show the regular FAQ fields for adding new faq item -->
              <div v-else-if="openNewFaqModal">
                <div class="mb-3">
                  <label for="question" class="form-label">Name</label>
                  <input type="text" class="form-control" id="question" v-model="faqForm.question" required />
                </div>
                <div class="mb-3" v-if="showAnswerField">
                  <label for="answer" class="form-label">Answer</label>
                  <textarea class="form-control" id="answer" v-model="faqForm.answer" rows="3" required></textarea>
                </div>
                <div class="mb-3">
                  <label for="order" class="form-label">Order</label>
                  <input type="number" class="form-control" id="order" v-model="faqForm.order" required />
                </div>
                <div class="mb-3" v-if="
                  context !== 'category' &&
                  context !== 'clone' &&
                  context !== 'editCategory'
                ">
                  <label for="category" class="form-label">Category</label>
                  <select class="form-select" id="category" v-model="faqForm.faq_category_id" required>
                    <option v-for="category in faqCategories" :key="category.id" :value="category.id">
                      {{ category.name }}
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="status" class="form-label">Status</label>
                  <select class="form-select" id="status" v-model="faqForm.status" required>
                    <option :value="true">Active</option>
                    <option :value="false">Inactive</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="is_draft" class="form-label">Is Draft</label>
                  <select class="form-select" id="is_draft" v-model="faqForm.is_draft" required>
                    <option :value="false">No</option>
                    <option :value="true">Yes</option>
                  </select>
                </div>
              </div>

              <!--for category and edit faqitem form-->
              <div v-else>
                <div class="mb-3">
                  <label for="question" class="form-label">Name</label>
                  <input type="text" class="form-control" id="question" v-model="faqForm.question" required />
                </div>
                <div class="mb-3" v-if="showAnswerField">
                  <label for="answer" class="form-label">Answer</label>
                  <textarea class="form-control" id="answer" v-model="faqForm.answer" rows="3" required></textarea>
                </div>
                <div class="mb-3">
                  <label for="order" class="form-label">Order</label>
                  <input type="number" class="form-control" id="order" v-model="faqForm.order" required />
                </div>
                <div class="mb-3" v-if="
                  context !== 'category' &&
                  context !== 'clone' &&
                  context !== 'editCategory'
                ">
                  <label for="category" class="form-label">Category</label>
                  <select class="form-select" id="category" v-model="faqForm.faq_category_id" required>
                    <option v-for="category in faqCategories" :key="category.id" :value="category.id">
                      {{ category.name }}
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="status" class="form-label">Status</label>
                  <select class="form-select" id="status" v-model="faqForm.status" required>
                    <option :value="true">Active</option>
                    <option :value="false">Inactive</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="is_draft" class="form-label">Is Draft</label>
                  <select class="form-select" id="is_draft" v-model="faqForm.is_draft" required>
                    <option :value="false">No</option>
                    <option :value="true">Yes</option>
                  </select>
                </div>
              </div>
              
              <button type="submit" class="btn-box mt-5">
                {{ isEditingFaq ? "Update Draft" : "Save Draft" }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebService from "@/services/WebService";

export default {
  name: "FaqPage",
  data() {
    return {
      context: null,
      // showFaqModal: false,   
      faqCategories: [],
      faqItems: [],
      faqIntro: {
        title: "Frequently Asked Questions",
        description:
          "Remote photoplethysmography (rPPG) is changing how we measure vital signs like heart rate, offering a non-contact, innovative solution. Below are some commonly asked questions to help you understand this groundbreaking technology.",
      },
      currentPage: 1,
      itemsPerPage: 10,
      loader: false,
      isEditingFaq: false,
      editFaqId: null,
      showAnswerField: true,
      faqForm: {
        question: "",
        answer: "",
        order: "",
        faq_category_id: "",
        status: true,
        is_draft: false,
      },
      isEditingFaqIntro: false,
      faqIntroForm: {
        title: "",
        description: "",
        date: "",
      },
      searchQuery: "",
      selectedCategory: null,
      categories: [],
    };
  },
  mounted() {
    this.getCategories();
    this.getFaqItems();
    this.getFaqIntro();
  },
  methods: {
    async toggleFaqStatus(item) {
      try {
        console.log("Toggling status for FAQ item:", item);
        const previousStatus = item.status;
        item.status = item.status === 1 ? 0 : 1;
        const response = await WebService.webRequest({
          _method: "PUT",
          _action: "faqs-status",
          _body: { id: item.id, status: item.status },
        });
        if (response.success) {
          console.log("FAQ status updated successfully:", response.data);
        } else {
          item.status = previousStatus;
          console.error("Failed to update FAQ status:", response.message);
        }
      } catch (error) {
        console.error("Error toggling FAQ status:", error.message);
        item.status = item.status === 1 ? 0 : 1;
      }
    },

    async submitFaqForm(event) {
      if (event) event.preventDefault();
      try {
        console.log("Form submission triggered");
        this.loader = true;

        // Handle FAQ Intro editing
        if (this.isEditingFaqIntro  ) {
          await this.updateFaqIntro();
        }
        // Handle category cloning
        else if (
          this.editFaqId &&
          !this.showAnswerField &&
          !this.isEditingFaq
        ) {
          await this.cloneCategoryAndItems();
        }

        // Handle category creation
        else if (!this.showAnswerField && !this.isEditingFaq) {
          await this.createCategory();
        }
        // Handle category editing
        else if (!this.showAnswerField && this.isEditingFaq) {
          await this.updateCategory();
        }
        // Handle FAQ editing
        else if (this.isEditingFaq && this.editFaqId) {
          await this.updateFaq();
        }

        // Handle new FAQ creation (default case)
        else  {
          await this.createNewFaq();
        }
      } catch (error) {
        console.error("Error in form submission:", error.message);
        //alert("An error occurred during form submission.");
        this.showNewFaqForm = false;
        this.loader = false;
        this.$toast.error(error.message, { position: "top-right" });
      } finally {
        this.loader = false;
      }
    },
  
    async cloneCategoryAndItems() {
      this.loader = true;
      try {
        console.log("Cloning category and its items with ID:", this.editFaqId);
        const response = await WebService.webRequest({
          _method: "POST",
          _action: "clone-categories",
          _body: {
            name: this.faqForm.question,
            usage: this.faqForm.usage || "in use",
            order: this.faqForm.order,
            category_id: this.editFaqId,
          },
        });
        if (response.success) {
          console.log("Category cloned successfully:", response);
          await this.getCategories();
          await this.getFaqItems();
          this.resetFaqForm();
          this.closeFaqModal();
          // window.location.reload();
          this.loader = false;
        } else {
          console.error("Error cloning category:", response.message);
         // alert("Failed to clone category: " + response.message);
          this.$toast.error(response.message, { position: "top-right" });
          this.loader = false;
        }
      } catch (error) {
        console.error("Error catch cloning category:", error.message);
        // alert(
        //   "An error occurred while cloning the category. Category name already exists maybe."
        // );
        this.$toast.error( "An error occurred while cloning the category.Try using unique category name.", { position: "top-right" });
        this.loader = false;
      }
    },
    async updateFaqIntro() {
      try {
        console.log("Updating FAQ Intro");
        const response = await WebService.webRequest({
          _method: "PUT",
          _action: "updateFaqIntro",
          _body: {
            title: this.faqIntroForm.title,
            description: this.faqIntroForm.description,
            date: this.faqIntroForm.date,
            last_edited_by: "Admin",
          },
        });
        if (response.success) {
          console.log("FAQ Intro updated successfully");
          await this.getFaqIntro();
          this.resetFaqForm();
          this.closeFaqModal();
        } else {
          console.error("Error updating FAQ Intro:", response.message);
          //alert("Failed to update FAQ Intro: " + response.message);
          this.$toast.error(response.message, { position: "top-right" });
        }
      } catch (error) {
        console.error("Error updating FAQ Intro:", error.message);
       // alert("An error occurred while updating the FAQ Intro.");
        this.$toast.error(error.message, { position: "top-right" });
      }
    },

    async updateFaq() {
      try {
        console.log("Updating FAQ with ID:", this.editFaqId);
        this.loader= true;
        const response = await WebService.webRequest({
          _method: "PUT",
          _action: "updateFAQ",
          _body: {
            id: this.editFaqId,
            question: this.faqForm.question,
            answer: this.faqForm.answer,
            faq_category_id: this.faqForm.faq_category_id,
            order: this.faqForm.order,
            status: this.faqForm.status,
            is_draft: this.faqForm.is_draft,
            last_edited_by: "Admin",
          },
        });
        if (response.success) {
          console.log("FAQ updated successfully");
          await this.getFaqItems();
          this.resetFaqForm();
          this.closeFaqModal();
         // window.location.reload();
          this.loader=false;
        } else {
          console.error("Error updating FAQ:", response.message);
          //alert("Failed to update FAQ: " + response.message);
          this.$toast.error(response.message, { position: "top-right" });
        }
      } catch (error) {
        console.error("Error updating FAQ:", error.message);
       // alert("An error occurred while updating the FAQ.");
       this.loader=false;
        this.$toast.error(error.message, { position: "top-right" });
        "FAQ item deleted successfully"
      }
    },
    async deleteFaq(id) {
      this.loader= true;
      if (confirm("Are you sure you want to delete this faq item?")) {
        try {
          const response = await WebService.webRequest({
            _method: "DELETE",
            _action: `faqs-delete/${id}`,
            _body: {},
          });
          if (response && response.success) {
            await this.getFaqItems();
           // alert("FAQ item deleted successfully");
            this.$toast.success("FAQ item deleted successfully", { position: "top-right" });
           // window.location.reload();
          } else {
            throw new Error(
              response.message || "Failed to delete the faq item"
            );
         
          }
          this.loader= false;
        } catch (error) {
          console.error("Error deleting FAQ item:", error);
          // alert(
          //   error.message || "An error occurred while deleting the Faq item"
          // );
          this.$toast.error(error.message, { position: "top-right" });
          this.loader= false;
        }
      }
    },
    async archiveCategory(id, currentArchiveStatus) {
      try {
        const formData = new FormData();
        // formData.append("archive", currentArchiveStatus === 1 ? "0" : "1");
        // formData.append("archive", currentArchiveStatus === "in use" ? "not used" : "in use");
        const response = await WebService.webRequest({
          _method: "PUT",
          _action: `categories/archive/${id}`,
          _body: formData,
        });

        if (response.success) {
          const categoryIndex = this.faqCategories.findIndex(
            (c) => c.id === id
          );
          if (categoryIndex !== -1) {
            this.faqCategories[categoryIndex].usage =
              currentArchiveStatus === "in use" ? "not used" : "in use";
          }
          await this.getCategories();
        } else {
          throw new Error(
            response.message || "Failed to update archive status"
          );
        }
      } catch (error) {
        console.error("Error updating archive status:", error);
       // alert("Error updating archive status");
        this.$toast.error(error, { position: "top-right" });
      }
    },

    async createNewFaq() {
      try {
        console.log("Creating new FAQ");
        const response = await WebService.webRequest({
          _method: "POST",
          _action: "storeFAQ",
          _body: {
            question: this.faqForm.question,
            answer: this.faqForm.answer,
            faq_category_id: this.faqForm.faq_category_id,
            order: this.faqForm.order,
            status: this.faqForm.status,
            is_draft: this.faqForm.is_draft,
            last_edited_by: "Admin",
          },
        });
        if (response.success) {
          console.log("FAQ created successfully");
          await this.getFaqItems();
          this.showNewFaqForm = false;
          this.resetFaqForm();
          //  this.closeFaqModal();
         
          // window.location.reload();
        } else {
          console.error("Error creating FAQ:", response.message);
        //  alert("Failed to create FAQ: " + response.message);
          this.$toast.error(response.message, { position: "top-right" });
       
        }
      } catch (error) {
        console.error("Error creating FAQ:", error.message);
       // alert("An error occurred while creating the FAQ.");
        this.$toast.error(error.message, { position: "top-right" });
      }
    },

    resetFaqForm() {
      this.faqForm = {
        question: "",
        answer: "",
        order: "",
        faq_category_id: "",
        status: true,
        is_draft: false,
      };
      this.faqIntroForm = {
        title: "",
        description: "",
        date: "",
      };
      this.isEditingFaq = false;
      this.editFaqId = null;
      this.isEditingFaqIntro = false;
      console.log("FAQ form reset");
    },

    closeFaqModal() {
      // this.showFaqModal = false;
      console.log("FAQ modal closed");
    },

    openFaqModal() {
      // this.showFaqModal = true;
      console.log("FAQ modal opened for new FAQ item");
    },
    //     openFaqModal() {
    //   this.showFaqModal = true;
    //   this.isEditingFaq = false;
    //   this.isEditingFaqIntro = false;
    //   this.editFaqId = null;
    //   this.showAnswerField = true; // Ensure the answer field is shown for FAQ items
    //   this.faqForm = {
    //     question: "",
    //     answer: "",
    //     order: "",
    //     faq_category_id: "",
    //     status: true,
    //     is_draft: false,
    //   };
    //   console.log("FAQ modal opened for new FAQ item");
    // },

    openAddCategoryModal() {
      console.log("Opening add category modal");
      this.context = "category";
      this.isEditingFaq = false;
      this.isEditingFaqIntro = false;
      this.editFaqId = null;
      this.showAnswerField = false;
      this.faqForm = {
        question: "", // Will be used as category name
        answer: "",
        order: 1,
        faq_category_id: "",
        status: true,
        is_draft: false,
        usage: "in use", // Add usage field for categories
      };
      this.openFaqModal();
    },
    openNewFaqModal() {
      console.log("Opening add faq-item modal");
      this.context = "faq";
      this.isEditingFaq = false;
      this.isEditingFaqIntro = false;
      this.editFaqId = null;
      this.showAnswerField = true;
      this.faqForm = {
        question: "", // Will be used as category name
        answer: "",
        order: 1,
        faq_category_id: "",
        status: true,
        is_draft: false,
        usage: "in use", // Add usage field for categories
      };
      this.openFaqModal();
    },
    // showFaqModal() {
    //   console.log("Opening add Faq item modal(showFaqModal)");
    //   this.context = "category";
    //   this.isEditingFaq = false;
    //   this.isEditingFaqIntro = false;
    //   this.editFaqId = null;
    //   this.showAnswerField = false;
    //   this.faqForm = {
    //     question: "", // Will be used as category name
    //     answer: "",
    //     order: 1,
    //     faq_category_id: "",
    //     status: true,
    //     is_draft: false,
    //     usage: "in use", // Add usage field for categories
    //   };
    //   this.openFaqModal();
    // },

    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const options = { month: "long", day: "numeric", year: "numeric" };
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        date
      );
      const day = date.getDate();
      const ordinal =
        day % 10 === 1 && day !== 11
          ? "st"
          : day % 10 === 2 && day !== 12
            ? "nd"
            : day % 10 === 3 && day !== 13
              ? "rd"
              : "th";
      return formattedDate.replace(String(day), `${day}${ordinal}`);
    },

    async getFaqIntro() {
      try {
        console.log("Fetching FAQ intro...");
        const response = await WebService.webRequest({
          _method: "GET",
          _action: "faq-intro",
          _body: {},
        });
        console.log("FAQ Intro Response:", response);
        this.faqIntro = response.data || {};
      } catch (error) {
        console.error("Error fetching FAQ intro:", error.message);
      }
    },

    async getCategories() {
      try {
        console.log("Fetching categories...");
        this.loader = true;
        const response = await WebService.webRequest({
          _method: "GET",
          _action: "categories",
          _body: {},
        });
        console.log("Categories Response:", response);
        this.faqCategories = response.data.map((category) => ({
          ...category,
          archive:
            category.archive === 1 || category.archive === "1"
              ? "not used"
              : "in use",
        }));
        this.loader = false;
      } catch (error) {
        console.error("Error fetching categories:", error.message);
        this.loader = false;
      }
    },

    async createCategory() {
      try {
        console.log("Submitting category form with data:", this.faqForm);
        this.loader = true;
        const response = await WebService.webRequest({
          _method: "POST",
          _action: "categories",
          _body: {
            name: this.faqForm.question, // Using question field for category name
            usage: this.faqForm.usage || "in use",
            order: this.faqForm.order,
          },
        });
        if (response.success) {
          console.log("Category created successfully:", response);
          await this.getCategories();
          this.resetFaqForm();
          this.closeFaqModal();
       
        } else {
          console.error("Error creating category:", response.message);
         // alert("Failed to create category: " + response.message);
          this.$toast.error(response.message, { position: "top-right" });
        }
      } catch (error) {
        console.error("Error creating category:", error.message);
       // alert("An error occurred while creating the category.");
        this.$toast.error(error.message, { position: "top-right" });
      } finally {
        this.loader = false;
      }
    },

    async updateCategory() {
      try {
        console.log("Updating category with ID:", this.editFaqId);
        this.loader = true;
        const response = await WebService.webRequest({
          _method: "PUT",
          _action: "categories/" + this.editFaqId,
          _body: {
            name: this.faqForm.question, // Using question field for category name
            usage: this.faqForm.status ? "in use" : "not used",
            order: this.faqForm.order,
          },
        });
        if (response.success) {
          console.log("Category updated successfully:", response);
          await this.getCategories();
          this.resetFaqForm();
          this.closeFaqModal();
        } else {
          console.error("Error updating category:", response.message);
         // alert("Failed to update category: " + response.message);
          this.$toast.error(response.message, { position: "top-right" });
        }
      } catch (error) {
        console.error("Error updating category:", error.message);
       // alert("An error occurred while updating the category.");
        this.$toast.error(error.message, { position: "top-right" });
        error
      } finally {
        this.loader = false;
      }
    },

    async getFaqItems() {
      try {
        console.log("Fetching FAQ items...");
        this.loader = true;
        const response = await WebService.webRequest({
          _method: "GET",
          _action: "get-faq",
          _body: {},
        });
        console.log("FAQ Items Response:", response);
        this.faqItems = response.data || [];
        this.loader = false;
      } catch (error) {
        console.error("Error fetching FAQ items:", error.message);
        this.loader = false;
      }
    },

    cloneCategory(id) {
      console.log("Cloning category ID:", id);
      this.context = "clone";
      const category = this.faqCategories.find((c) => c.id === id);
      if (!category) {
        console.log("Category not found with ID:", id);
        return;
      }
      this.isEditingFaqIntro = false;
      this.isEditingFaq = false;
      this.editFaqId = id;
      this.faqForm.question = category.name;
      this.faqForm.answer = "";
      this.showAnswerField = false;
      this.faqForm.order = category.order;
      this.faqForm.status = category.usage === "in use";
      this.faqForm.is_draft = false;
      this.faqForm.usage = category.usage;

      console.log("Category form populated for cloning:", this.faqForm);
      this.openFaqModal();
    },

    editCategory(id) {
      console.log("Edit triggered for category ID:", id);
      this.context = "editCategory";
      const category = this.faqCategories.find((c) => c.id === id);
      if (!category) {
        console.log("Category not found with ID:", id);
        return;
      }

      this.isEditingFaqIntro = false;
      this.isEditingFaq = true;
      this.editFaqId = id;
      this.faqForm.question = category.name;
      this.faqForm.answer = ""; // Not applicable for categories
      this.showAnswerField = false;
      this.faqForm.order = category.order;
      this.faqForm.status = category.usage === "in use";
      this.faqForm.is_draft = false;
      this.faqForm.usage = category.usage;

      console.log("Category form populated for editing:", this.faqForm);
      this.openFaqModal();
    },
 
    selectCategory(id) {
      this.selectedCategory = id;
      console.log("Selected category:", id);
    },
    sorting(event, column) {
      console.log("Sorting by column:", column);
    },

    editFaq(id) {
      console.log("Edit triggered for FAQ ID:", id);
      this.context = "faq";
      this.isEditingFaqIntro = false;
      const item = this.faqItems.find((i) => i.id === id);
      if (!item) {
        console.log("FAQ item not found with ID:", id);
        return;
      }
      this.isEditingFaq = true;
      this.editFaqId = id;
      this.faqForm.question = item.question;
      this.faqForm.answer = item.answer;
      this.showAnswerField = true;
      this.faqForm.order = item.order;
      this.faqForm.faq_category_id = item.faq_category_id;
      this.faqForm.status = item.status === 1;
      this.faqForm.is_draft = !!item.is_draft;
      console.log("FAQ form populated for FAQ item editing:", this.faqForm);
      this.openFaqModal();
    },
    editFaqIntro() {
      console.log("Edit FAQ Intro triggered");
      this.isEditingFaqIntro = true;
      this.isEditingFaq = false;
      this.faqIntroForm.title = this.faqIntro.title;
      this.faqIntroForm.description = this.faqIntro.description;
      this.faqIntroForm.date =
        this.faqIntro.date || new Date().toISOString().substring(0, 10);
      console.log("FAQ Intro form populated:", this.faqIntroForm);
      this.openFaqModal();
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #402858;
}

input:checked+.slider:before {
  transform: translateX(14px);
}

.faq-intro {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.faq-intro-title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 1.3rem;
}

.faq-intro-title1 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.faq-intro-description {
  font-size: 0.8rem;
  color: #555;
}

.page-title {
  color: #351d4e;
  font-weight: 600;
}

.table-style th {
  position: relative;
}

.sort-icon {
  cursor: pointer;
  margin-right: 0.5rem;
}

.action-ele {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.spinner-color {
  color: #351d4e;
}

.Intro {
  margin-top: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.modal-body {
  padding: 15px 0;
}

.disabled {
  opacity: 0.4;
  filter: grayscale(100%);
}

.action-ele a.disabled img {
  opacity: 0.4;
  filter: grayscale(100%);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  background-color: #f8f9fa;
  transition: background-color 0.2s ease;
}

.save-Btn {
  background-color: #4b286d;
  color: white;
  border-radius: 1.1rem;
}

.faq-categories .btn {
  border-radius: 20px;
}

.faq-categories .btn.active {
  background-color: #007bff;
  color: white;
}

.addNew_btn {
  font-size: 0.8rem;
  font-weight: bold;
  color: #4b286d;
  text-decoration: none;
  justify-content: space-between;
}

.AddCategory_btn {
  font-size: 0.8rem;
  color: #4b286d;
  font-weight: bold;
  text-decoration: none;
  justify-content: space-between;
}

.search_input {
  width: 30%;
  border: none;
  padding: 0.5rem;
  color: #351d4e;
  border-radius: 0.8rem;
}

.btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  border-radius: 50px;
  min-width: 180px;
  padding: 0 20px;
  outline: 0 !important;
  border: 0 !important;
  background-color: #4B286D;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  width: fit-content;
}
</style>
