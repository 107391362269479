<template>
  <div class="mt-3">
    <a href="" class="text-secondary fw-light"> <img src="images/back-arrow.svg" width="18" class="me-1"> Back </a>
  </div>
  <div class="row">
    <div class="col-lg-8">
      <div class="row mb-3">
        <div class="col-md-6">
          <div class="page-card">
            <div class="d-flex align-items-center gap-2">
              <div class=" border-end border-danger pe-3">
                <p class=" text-secondary fw-medium">Scans</p>
                <p class="h2">48 | <span class="text-danger">0.3%</span></p>
                <p class=" text-secondary font-14">Some text</p>
              </div>
              <div>
                <ul class=" text-secondary font-12 mb-0">
                  <li>Failing reason 1</li>
                  <li>Failing reason 2</li>
                  <li>Failing reason 3</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="page-card">
            <div class="d-flex align-items-center gap-2">
              <div class=" border-end pe-3">
                <p class=" text-secondary fw-medium">Shared</p>
                <p class="h2">23</p>
                <p class=" text-secondary font-14">Some text</p>
              </div>
              <div>
                <ul class=" text-secondary font-12 mb-0">
                  <li>Failing reason 1</li>
                  <li>Failing reason 2</li>
                  <li>Failing reason 3</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-card mb-3">
        <div class="row align-items-center mb-3 border-bottom">
          <div class="col-md-6 d-flex align-items-center gap-4">
            <h2 class="page-title">User activities</h2>
          </div>
          <div class="col-md-6 text-end">
            <select class="form-select border-0 w-auto ms-auto font-14 fw-light" aria-label="Default select example">
              <option selected>Last 7 Days</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>
        <p>Graph here</p>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="page-card">
        <div class="text-center mt-3 pb-3 mb-3 border-bottom">
          <img src="images/placeholder.png" width="150" height="150" class=" object-fit-cover rounded-circle mb-3"
            alt="">
          <p class=" font-24 fw-bold mb-1">Syaiful Rijal</p>
          <p class=" text-secondary">Mail</p>
          <button class="btn btn-dark-brand px-5 mb-3">Edit Profile</button>
        </div>
        <table class=" table table-borderless font-14 mb-lg-5">
          <tbody>
            <tr>
              <td class="text-secondary">Email</td>
              <td class="text-end">yourname@gmail.com</td>
            </tr>
            <tr>
              <td class="text-secondary">Phone</td>
              <td class="text-end">+1 234 5678</td>
            </tr>
            <tr>
              <td class="text-secondary">Date of birth</td>
              <td class="text-end">23.09.1996 </td>
            </tr>
            <tr>
              <td class="text-secondary">Account created</td>
              <td class="text-end">23.09.2023 | 2:12 pm</td>
            </tr>
            <tr>
              <td class="text-secondary">Status</td>
              <td class="text-end">Active</td>
            </tr>
          </tbody>
        </table>

        <p class=" text-secondary mb-2">Note</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserProfile",
  data() {
    return {};
  },
  mounted() {

  },
  methods: {},
};
</script>
