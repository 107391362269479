<template>
  <div class="">
    <div class="row space">
      <div class="col-md-5 intro">
        <div class="col-md-12 page-card">
          <!-- Trivia Categories Header -->
          <div class=" mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="page-title">Trivia</h2>
              <a href="javascript:void(0)" @click="openTriviaModal('trivia')"
                class="btn btn-link d-flex align-items-center ms-2 text-decoration-none AddNew_Btn">
                Add NEW <i class="bi bi-plus-circle-fill fs-5 ms-2"></i>
              </a>
            </div>

          </div>

          <!-- Trivia Categories Table -->
          <div class="table-wrap">
            <table class="table table-style">
              <thead>
                <tr>
                  <th scope="col">
                    <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'id')" />
                    ID
                  </th>
                  <th scope="col">
                    <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'name')" />
                    Name
                  </th>
                  <th scope="col" class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loader">
                  <td colspan="5" class="text-center">
                    <div class="spinner-border spinner-color" role="status"></div>
                  </td>
                </tr>
                <tr v-for="item in triviaItems" :key="item.id" :class="{ 'selected-row': selectedRowId === item.id }"
                  @click="selectRow(item.id)">
                  <td>TR{{ item.id }}</td>
                  <td @click.stop="openEditForm(item, 'trivia')" class="cursor-pointer">
                    {{ item.title }}
                  </td>
                  <td class="text-center">
                    <div class="action-ele">
                      <a href="javascript:void(0)" @click="openEditForm(item, 'trivia')" title="Edit">
                        <img src="/images/icon-edit.svg" width="22" alt="Edit" />
                      </a>
                      <a href="javascript:void(0)" @click="viewTrivia(item.id, item.archive)"
                        :class="{ disabled: item.archive === 1 }" title="Archive">
                        <img src="/images/icon-archive.svg" width="22"
                          :alt="item.archive === 1 ? 'Unarchive' : 'Archive'" />
                      </a>
                      <a href="javascript:void(0)" @click="deleteTrivia(item.id)" title="Delete">
                        <img src="/images/icon-delete.svg" width="18" alt="Delete" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-12 page-card">
          <!-- Trivia Categories Header -->
          <div class=" mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="page-title">ADS</h2>
              <a href="javascript:void(0)" @click="openTriviaModal('ads')"
                class="btn btn-link d-flex align-items-center ms-2 text-decoration-none AddNew_Btn">
                Add NEW <i class="bi bi-plus-circle-fill fs-5 ms-2"></i>
              </a>
            </div>
            <!-- <div class="col-md-6">
              <div
                class="d-flex justify-content-end font-12 text-uppercase gap-2 align-items-center">
                <span>show</span>
                <div class="bg-light-brand px-2 py-1 rounded">
                  {{ itemsPerPage }}
                </div>
                <span>entries</span>
              </div>
            </div> -->
          </div>

          <!-- Trivia Categories Table -->
          <div class="table-wrap">
            <table class="table table-style">
              <thead>
                <tr>
                  <th scope="col">
                    <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'id')" />
                    ID
                  </th>
                  <th scope="col">
                    <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'name')" />
                    Name
                  </th>
                  <th scope="col">
                    <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'order')" />
                    order
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loader">
                  <td colspan="6" class="text-center">
                    <div class="spinner-border spinner-color" role="status"></div>
                  </td>
                </tr>
                <tr v-else v-for="item in adsItems" :key="item.id">
                  <td>AD{{ item.id }}</td>
                  <td @click="openEditForm(item, 'ads')" class="cursor-pointer hover:bg-gray-100"
                    style="cursor: pointer">
                    {{ item.title }}
                  </td>
                  <td>{{ item.order }}</td>
                  <td>
                    <div class="action-ele">
                      <a href="javascript:void(0)" @click="openEditForm(item, 'ads')" title="Edit">
                        <img src="/images/icon-edit.svg" width="22" alt="Edit" />
                      </a>
                      <a href="javascript:void(0)" @click="viewAds(item.id, item.archive)"
                        :class="{ disabled: item.archive === 1 }" title="Archive">
                        <img src="/images/icon-archive.svg" width="22"
                          :alt="item.archive === 1 ? 'Unarchive' : 'Archive'" />
                      </a>
                      <a href="javascript:void(0)" @click="deleteTrivia(item.id)" title="Delete">
                        <img src="/images/icon-delete.svg" width="18" alt="Delete" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Audio Section -->
        <div class="col-md-12 page-card">
          <div class=" mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="page-title">Audio</h2>
              <a href="javascript:void(0)" @click="openTriviaModal('audio')"
                class="btn btn-link d-flex align-items-center ms-2 text-decoration-none AddNew_Btn">
                Add NEW <i class="bi bi-plus-circle-fill fs-5 ms-2"></i>
              </a>
            </div>

          </div>

          <div class="table-wrap">
            <table class="table table-style">
              <thead>
                <tr>
                  <th scope="col">
                    <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'id')" />
                    ID
                  </th>
                  <th scope="col">
                    <img src="/images/sort-icon.svg" class="sort-icon" @click="sorting($event, 'name')" />
                    Name
                  </th>
                  <th scope="col" class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loader">
                  <td colspan="5" class="text-center">
                    <div class="spinner-border spinner-color" role="status"></div>
                  </td>
                </tr>
                <tr v-for="item in audioItems" :key="item.id" :class="{ 'selected-row': selectedRowId === item.id }"
                  @click="selectRow(item.id)">
                  <td>AU{{ item.id }}</td>
                  <td @click.stop="openEditForm(item, 'audio')" class="cursor-pointer">
                    {{ item.title }}
                  </td>
                  <td class="text-center">
                    <div class="action-ele">
                      <label class="switch">
                        <input type="checkbox" :checked="item.archive === 0" @change="toggleAudioStatus(item)" />
                        <span class="slider round"></span>
                      </label>
                      <a href="javascript:void(0)" @click="openEditForm(item, 'audio')" title="Edit">
                        <img src="/images/icon-edit.svg" width="22" alt="Edit" />
                      </a>
                      <a href="javascript:void(0)" @click="deleteAudio(item.id)" title="Delete">
                        <img src="/images/icon-delete.svg" width="18" alt="Delete" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-7">
        <div class="page-card">
          <div>
            <div>
              <h5 class="modal-title">
                {{ isEditing ? "Edit" : "Add New" }}
                {{
                  context === "trivia"
                    ? "Trivia"
                    : context === "ads"
                      ? "ADS"
                      : "Audio"
                }}
              </h5>
            </div>
            <div class="modal-body">
              <form @submit.prevent="isEditing ? updateTrivia() : createTrivia()">
                <div class="mb-3">
                  <label for="title" class="form-label">Title/Text</label>
                  <input type="text" class="form-control" id="title" v-model="TriviaForm.title"
                    placeholder="Enter Title" required />
                </div>
                <!-- Audio Form Fields -->
                <div v-if="context === 'audio'" class="mb-3">
                  <label for="audio_file" class="form-label">Audio File</label>
                  <input type="file" class="form-control" id="audio_file" @change="handleAudioFileUpload" 
                  accept="audio/mpeg, audio/wav, audio/ogg"
                  />
                  <small v-if="TriviaForm.media_file">Current File:
                    {{
                      TriviaForm.media_file.name || TriviaForm.media_file
                    }}</small>
                </div>
                <!-- <div v-if="context == 'audio'" class="mb-3">
                  <label for="media_type" class="form-label">Media Type</label>
                  <select
                    class="form-select"
                    id="media_type"
                    v-model="TriviaForm.media_type"
                    required>
                    <option value="audio">audio</option>
                  </select>
                </div> -->
                <!-- ADS Form Fields -->
                <div v-if="context === 'ads'" class="mb-3">
                  <label for="media_file" class="form-label">Media File</label>
                  <input type="file" class="form-control" id="media_file" @change="handleFileUpload" 
                  accept="image/jpeg, image/webp, image/png, image/gif,video/mkv, video/mp4, video/webm, video/x-matroska, audio/mp3, audio/mpeg, audio/wav, audio/ogg"
                  />
                  <small v-if="TriviaForm.media_file">Current File:
                    {{
                      TriviaForm.media_file.name || TriviaForm.media_file
                    }}</small>
                </div>
                <div v-if="context === 'ads'" class="mb-3">
                  <label for="media_type" class="form-label">Media Type</label>
                  <select class="form-select" id="media_type" v-model="TriviaForm.media_type" required disabled>
                    <option value="video">video</option>
                    <option value="image">image</option>
                    <option value="gif">gif</option>
                  </select>
                </div>
                <div class="mb-3" v-if="context !== 'audio'">
                  <label for="order" class="form-label">Order</label>
                  <input type="number" class="form-control" id="order" v-model="TriviaForm.order"
                    placeholder="Enter Display Order" required />
                </div>
                <div class="mb-3" v-if="context !== 'audio'">
                  <label for="duration" class="form-label">Duration (in seconds)</label>
                  <input type="number" class="form-control" id="duration" v-model="TriviaForm.duration"
                    placeholder="Enter Duration" required />
                </div>
                <div class="mb-3" v-if="context !== 'audio'">
                  <label for="start_time" class="form-label">Start Time</label>
                  <input type="datetime-local" class="form-control" id="start_time" v-model="TriviaForm.start_time"
                    required />
                </div>
                <div class="mb-3" v-if="context !== 'audio'">
                  <label for="end_time" class="form-label">End Time</label>
                  <input type="datetime-local" class="form-control" id="end_time" v-model="TriviaForm.end_time"
                    required />
                </div>
                <button type="submit" class="btn-box mt-5">
                  {{ isEditing ? "Update Draft" : "Save Draft" }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebService from "@/services/WebService";

export default {
  name: "TriviaPage",
  data() {
    return {
      showTriviaModal: false,
      triviaItems: [],
      adsItems: [],
      audioItems: [],
      context: "trivia",
      isEditing: false,
      currentEditingId: null,
      loader: false,
      itemsPerPage: 10,
      TriviaForm: {
        title: "",
        media_file: null,
        media_type: "text" | "video" | "image" | "gif" | "audio",
        order: "",
        duration: "",
        start_time: "",
        end_time: "",
      },
    };
  },
  created() {
    this.initializeData();
  },
  methods: {
    selectRow(id) {
      this.selectedRowId = id;
    },
    async initializeData() {
      await Promise.all([
        this.getTriviaItems(),
        this.getAdsItems(),
        this.getAudioItems(),
      ]);
    },


    async toggleAudioStatus(item) {
      try {
        console.log("Toggling status for audio item:", item);
        const previousStatus = item.status;
        item.status = item.status === 1 ? 0 : 1;
        const response = await WebService.webRequest({
          _method: "PUT",
          _action: `trivia/archive/${item.id}`,
          _body: { id: item.id, status: item.status },
        });
        if (response.success) {
          console.log("audio status updated successfully:", response.data);
        } else {
          item.status = previousStatus;
          console.error("Failed to update audio status:", response.message);
        }
      } catch (error) {
        console.error("Error toggling audio status:", error.message);
        item.status = item.status === 1 ? 0 : 1;
      }
    },
    async getTriviaItems() {
      try {
        this.loader = true;
        const response = await WebService.webRequest({
          _method: "GET",
          _action: "trivia-text",
          _body: {},
        });
        if (response?.data) {
          this.triviaItems = response.data.map((item) => ({
            ...item,
            archive: parseInt(item.archive),
          }));
        } else {
          this.triviaItems = [];
        }
      } catch (error) {
        console.error("Error fetching Trivia items:", error);
        this.triviaItems = [];
      } finally {
        this.loader = false;
      }
    },
    async getAdsItems() {
      try {
        this.loader = true;
        const response = await WebService.webRequest({
          _method: "GET",
          _action: "trivia",
          _body: {},
        });
        if (response?.data) {
          this.adsItems = response.data.map((item) => ({
            ...item,
            archive: parseInt(item.archive),
          }));
        } else {
          this.adsItems = [];
        }
      } catch (error) {
        console.error("Error fetching ADS items:", error);
        this.adsItems = [];
      } finally {
        this.loader = false;
      }
    },
    async getAudioItems() {
      try {
        this.loader = true;
        const response = await WebService.webRequest({
          _method: "GET",
          _action: "trivia-audio",
          _body: {},
        });
        if (response?.data) {
          this.audioItems = response.data.map((item) => ({
            ...item,
            archive: parseInt(item.archive),
          }));
        } else {
          this.audioItems = [];
        }
      } catch (error) {
        console.error("Error fetching Audio items:", error);
        this.audioItems = [];
      } finally {
        this.loader = false;
      }
    },
    formatDateTime(dateStr) {
      if (!dateStr) return "";
      const date = new Date(dateStr);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:00`;
    },
    async createTrivia() {
      try {
        this.loader = true;
        if (!this.TriviaForm.title?.trim()) {
          throw new Error("Title is required");
        }
        const formData = new FormData();
        formData.append("title", this.TriviaForm.title.trim());

        if (this.context === "audio") {
          if (this.TriviaForm.media_type) {
            formData.append("media_type", this.TriviaForm.media_type);
          }
          if (this.TriviaForm.media_file) {
            formData.append("media_file", this.TriviaForm.media_file);
          }
          // Static fields for audio
          formData.append("order", "0");
          formData.append("duration", "16");
          formData.append("start_time", "2025-02-28 16:58:00");
          formData.append("end_time", "2026-02-28 16:58:00");

        } else {
          formData.append("order", this.TriviaForm.order);
          formData.append("duration", this.TriviaForm.duration);
          const startTime = this.formatDateTime(this.TriviaForm.start_time);
          const endTime = this.formatDateTime(this.TriviaForm.end_time);
          formData.append("start_time", startTime);
          formData.append("end_time", endTime);
          if (this.context === "ads") {
            if (this.TriviaForm.media_type) {
              formData.append("media_type", this.TriviaForm.media_type);
            }
            if (this.TriviaForm.media_file) {
              formData.append("media_file", this.TriviaForm.media_file);
            }
          } else {
            formData.append("media_type", "text");
          }
        }

        console.log("Submitting form data:");
        for (let pair of formData.entries()) {
          console.log(pair[0] + ": " + pair[1]);
        }

        const response = await WebService.webRequest({
          _method: "POST",
          _action: "trivia",
          _body: formData,
        });
        if (response.success) {
          await this.initializeData();
          this.resetTriviaForm();
        } else {
          throw new Error(response.message || "Failed to create record");
        }
      } catch (error) {
        console.error("Error in createTrivia:", error);
        alert(error.message || "An error occurred while creating the record");
      } finally {
        this.loader = false;
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const allowedTypes = [
          "image/jpeg",
          "image/png",
          "image/webp",
          "image/gif",
          "video/mp4",
          "video/webm",
        ];
        if (!allowedTypes.includes(file.type)) {
          alert(
            "Invalid file type. Please upload jpg, png, gif, or mp4 files only."
          );
          event.target.value = "";
          return;
        }
        if (file.size > 20 * 1024 * 1024) {
          alert("File size must not exceed 20MB");
          event.target.value = "";
          return;
        }
        this.TriviaForm.media_file = file;
        this.TriviaForm.media_type = this.getMediaType(file.type);
        console.log(
          "File selected:",
          file.name,
          "Type:",
          this.TriviaForm.media_type
        );
      }
    },
    handleAudioFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const allowedTypes = ["audio/mpeg", "audio/wav", "audio/ogg"];
        if (!allowedTypes.includes(file.type)) {
          alert(
            "Invalid file type. Please upload mp3, wav, or ogg files only."
          );
          event.target.value = "";
          return;
        }
        if (file.size > 20 * 1024 * 1024) {
          alert("File size must not exceed 20MB");
          event.target.value = "";
          return;
        }
        this.TriviaForm.media_file = file;
        this.TriviaForm.media_type = this.getMediaType(file.type);
        console.log(
          "Audio file selected:",
          file.name,
          "Type:",
          this.TriviaForm.media_type
        );
      }
    },
    getMediaType(mimeType) {
      const validTypes = {
        "image/jpeg": "image",
        "image/png": "image",
        "image/gif": "gif",
        "image/webp": "image", 
        "video/mp4": "video",
        "video/webm": "video",
        "video/x-matroska": "video",
        "video/mkv": "video",
        "audio/mpeg": "audio",
        "audio/wav": "audio",
        "audio/mp3": "audio", 
        "audio/ogg": "audio",
      };
      return validTypes[mimeType] || "text";
    },
    webRequest({ _method, _action, _body, _headers = {} }) {
      const url = `/api/${_action}`;
      const config = {
        method: _method,
        headers: {
          Accept: "application/json",
          ..._headers,
        },
      };
      if (!(_body instanceof FormData)) {
        config.headers["Content-Type"] = "application/json";
        config.body = JSON.stringify(_body);
      } else {
        config.body = _body;
      }
      return fetch(url, config).then((response) => response.json());
    },
    resetTriviaForm() {
      this.TriviaForm = {
        title: "",
        media_file: null,
        media_type: this.context === "trivia" ? "text" : "",
        order: "",
        duration: "",
        start_time: "",
        end_time: "",
      };
      this.isEditing = false;
      this.currentEditingId = null;
    },
    openTriviaModal(context) {
      this.context = context;
      this.isEditing = false;
      this.currentEditingId = null;
      this.resetTriviaForm();
    },
    async updateTrivia() {
      try {
        this.loader = true;
        const formData = new FormData();
        if (this.TriviaForm.title?.trim()) {
          formData.append("title", this.TriviaForm.title.trim());
        }
        if (this.context !== "audio") {
          if (this.TriviaForm.order) {
            formData.append("order", this.TriviaForm.order);
          }
          if (this.TriviaForm.duration) {
            formData.append("duration", this.TriviaForm.duration);
          }
          if (this.TriviaForm.start_time) {
            const startTime = this.formatDateTime(this.TriviaForm.start_time);
            formData.append("start_time", startTime);
          }
          if (this.TriviaForm.end_time) {
            const endTime = this.formatDateTime(this.TriviaForm.end_time);
            formData.append("end_time", endTime);
          }
        }
        if (this.context === "ads" || this.context === "audio") {
          formData.append("media_type", this.TriviaForm.media_type);
          if (this.TriviaForm.media_file instanceof File) {
            formData.append("media_file", this.TriviaForm.media_file);
          }
        } else {
          formData.append("media_type", "text");
        }
        console.log("Updating form data for ID:", this.currentEditingId);
        for (let pair of formData.entries()) {
          console.log(pair[0] + ": " + pair[1]);
        }
        const response = await WebService.webRequest({
          _method: "POST",
          _action: `trivia/${this.currentEditingId}`,
          _body: formData,
          _headers: {},
        });
        if (response.success) {
          await this.initializeData();
          this.resetTriviaForm();
          this.isEditing = false;
          this.currentEditingId = null;
        } else {
          throw new Error(response.message || "Failed to update record");
        }
      } catch (error) {
        console.error("Error in updateTrivia:", error);
        alert(error.message || "An error occurred while updating the record");
      } finally {
        this.loader = false;
      }
    },
    async viewTrivia(id, currentArchiveStatus) {
      try {
        const formData = new FormData();
        formData.append("archive", currentArchiveStatus === 1 ? "0" : "1");
        const response = await WebService.webRequest({
          _method: "PUT",
          _action: `trivia/archive/${id}`,
          _body: formData,
        });
        if (response.success) {
          await this.initializeData();
        } else {
          throw new Error(
            response.message || "Failed to update archive status"
          );
        }
      } catch (error) {
        console.error("Error updating archive status:", error);
        alert("Error updating archive status");
      }
    },
    async viewAds(id, currentArchiveStatus) {
      try {
        const formData = new FormData();
        formData.append("archive", currentArchiveStatus === 1 ? "0" : "1");
        const response = await WebService.webRequest({
          _method: "PUT",
          _action: `trivia/archive/${id}`,
          _body: formData,
        });
        if (response.success) {
          await this.initializeData();
        } else {
          throw new Error(
            response.message || "Failed to update archive status"
          );
        }
      } catch (error) {
        console.error("Error updating archive status:", error);
        alert("Error updating archive status");
      }
    },
    async deleteTrivia(id) {
      if (confirm("Are you sure you want to delete this item?")) {
        try {
          const response = await WebService.webRequest({
            _method: "DELETE",
            _action: `trivia/${id}`,
            _body: {},
          });
          if (response && response.success) {
            await this.getTriviaItems();
            window.location.reload();
          }
        } catch (error) {
          console.error("Error deleting Trivia:", error);
          alert("Error deleting item");
        }
      }
    },
    async deleteAudio(id) {
      if (confirm("Are you sure you want to delete this audio item?")) {
        try {
          const response = await WebService.webRequest({
            _method: "DELETE",
            _action: `trivia/${id}`,
            _body: {},
          });
          if (response && response.success) {
            await this.getAudioItems();
            alert("Audio deleted successfully");
            window.location.reload();
          } else {
            throw new Error(response.message || "Failed to delete the audio");
          }
        } catch (error) {
          console.error("Error deleting audio:", error);
          alert(error.message || "An error occurred while deleting the audio");
        }
      }
    },
    openEditForm(item, context) {
      this.context = context;
      this.isEditing = true;
      this.currentEditingId = item.id;
      this.selectedRowId = item.id;
      const startDate = new Date(item.start_time);
      const endDate = new Date(item.end_time);
      this.TriviaForm = {
        title: item.title,
        media_file: item.media_path,
        media_type: item.media_type,
        order: item.order,
        duration: item.duration,
        start_time: startDate.toISOString().slice(0, 16),
        end_time: endDate.toISOString().slice(0, 16),
      };
    },
    async deleteAds(id) {
      if (confirm("Are you sure you want to delete this item?")) {
        try {
          const response = await WebService.webRequest({
            _method: "DELETE",
            _action: `trivia/${id}`,
            _body: {},
          });
          if (response && response.success) {
            await this.getAdsItems();
            alert("Ad deleted successfully");
            window.location.reload();
          } else {
            throw new Error(response.message || "Failed to delete the ad");
          }
        } catch (error) {
          console.error("Error deleting ad:", error);
          alert(error.message || "An error occurred while deleting the ad");
        }
      }
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #4B286D;
}

input:checked+.slider:before {
  transform: translateX(14px);
}

.Trivia-intro {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.Trivia-intro-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.selected-row {
  background-color: #43c8da;
  transition: background-color 0.3s ease;
}

.Trivia-intro-title1 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.Trivia-intro-description {
  font-size: 1rem;
  color: #555;
}

.page-title {

  font-weight: 600;
  color: #4B286D;
}

.table-style th {
  position: relative;
}

.sort-icon {
  cursor: pointer;

}

.action-ele {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.spinner-color {
  color: #351d4e;
}

.Intro {
  margin-top: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.modal-body {
  padding: 15px 0;
}

.disabled {
  opacity: 0.4;
  filter: grayscale(100%);
}

.action-ele a.disabled img {
  opacity: 0.4;
  filter: grayscale(100%);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  background-color: #f8f9fa;
  transition: background-color 0.2s ease;
}

.AddNew_Btn {
  font-size: 0.8rem;
  color: #4B286D;
  font-weight: bold;
}

.btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  border-radius: 50px;
  min-width: 180px;
  padding: 0 20px;
  outline: 0 !important;
  border: 0 !important;
  background-color: #4B286D;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  width: fit-content;
}
</style>
