<!-- <template>

   <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0  left-menu-bar">
                <div class="d-flex flex-column align-items-center align-items-sm-start ps-3 pt-2 text-white min-vh-100 left-menu-wrap">
                    <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                        <span class="fs-5 d-none d-sm-inline"> <img src="images/MVS-logo-white.svg"  width="150"/> </span>
                    </a>
                    <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start left-navigation" id="menu">
                        <li class="nav-item">
                            <a href="javascript:void(0)" v-on:click="$router.push('/dashboard')" class="nav-link align-middle px-0">
                                <i class="fs-4 bi bi-grid-fill"></i> <span class="ms-1 d-none d-sm-inline">{{ $t("menus.dashboard") }}</span>
                            </a>
                        </li>
                         
                        <li class="nav-item"> 
                            <a href="javascript:void(0)" v-on:click="$router.push('/apps')" class="nav-link px-0 align-middle">
                                <i class="fs-4 bi-table"></i> <span class="ms-1 d-none d-sm-inline">{{ $t("menus.apps") }}</span>
                            </a>
                        </li>
                        <li class="nav-item"> 
                            <a href="javascript:void(0)" v-on:click="$router.push('/users')" class="nav-link px-0 align-middle">
                                <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline">{{ $t("menus.users") }}</span>
                            </a>
                        </li>
                    
                        <li class="nav-item">
                            <a href="#submenu3" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
                                <i class="fs-4 bi-file-earmark-text"></i> <span class="ms-1 d-none d-sm-inline">{{ $t("menus.content_admin") }}</span> </a>
                                <ul class="nav flex-column ms-1" id="submenu3" data-bs-parent="#menu">
                                <li class="w-100">
                                    <a href="javascript:void(0)" v-on:click="$router.push('/pages')" class="nav-link px-0"> <span class="d-none d-sm-inline">{{ $t("menus.pages") }}</span> </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" v-on:click="$router.push('/questionnaire')" class="nav-link px-0"> <span class="d-none d-sm-inline">{{ $t("menus.questionnaire") }}</span> </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" v-on:click="$router.push('/vitals')" class="nav-link px-0"> <span class="d-none d-sm-inline">{{ $t("menus.vitals") }}</span> </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" v-on:click="$router.push('/faq')" class="nav-link px-0"> <span class="d-none d-sm-inline">{{ $t("menus.faq") }}</span> </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" v-on:click="$router.push('/trivia')" class="nav-link px-0"> <span class="d-none d-sm-inline">{{ $t("menus.trivia") }}</span> </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" v-on:click="$router.push('/setting')" class="nav-link px-0 align-middle">
                                <i class="fs-4 bi-gear"></i> <span class="ms-1 d-none d-sm-inline">{{ $t("menus.settings") }}</span> </a>
                        </li>
                    </ul>
                   
                    <div class="text-center pe-3 w-100 pb-3">
                        <div class="bg-white bg-opacity-10 p-3">
                            <img src="images/tech-spp-logo.svg" alt="">
                            <p class=" fs-5 mb-2 text-white"></p>
                            <a href="javascript:void(0)" v-on:click="onLogout()" class="text-white text-decoration-none fs-6 font-light"><i class="bi bi-box-arrow-left"></i> {{ $t("menus.logout") }}</a>
                        </div>
                    </div>
                </div>
            </div>

</template>

<script>
export default {
  name: "SideBar",
  data(){
    return {
        admin:null,
    };
  },
  mounted(){
    this.onGetMe();
  },
  methods:{
        onGetMe() {
            var method = "GET";
            this.$api
                .webRequest({
                    _method: method,
                    _action: "me",
                }).then((res) => {
                    this.admin = res.info;
                })
                .catch((e) => {
                    this.$toast.error(e.message, { position: "top-right"});
                });
        },
        onLogout() {
            var method = "POST";
            this.$api
            .webRequest({
                _method: method,
                _action: "logout",
            }).then((res) => {
                this.$toast.success(res.message, { position: "top-right"});
                localStorage.clear();
                this.$router.push("/login");    
            })
            .catch((e) => {
                this.$toast.error(e.message, { position: "top-right"});
            });
        },
  }
};
</script> -->

<template>
  <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 left-menu-bar">
    <div class="d-flex flex-column align-items-center align-items-sm-start ps-3 pt-2 text-white min-vh-100 left-menu-wrap">
      <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
        <span class="fs-5 d-none d-sm-inline">
          <img src="images/MVS-logo-white.svg" width="150" />
        </span>
      </a>

      <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start left-navigation" id="menu">
        <li class="nav-item">
          <a 
            href="javascript:void(0)" 
            v-on:click="$router.push('/dashboard')" 
            :class="['nav-link align-middle px-0', isActive('/dashboard')]"
          >
            <i class="fs-4 bi bi-grid-fill"></i> 
            <span class="ms-1 d-none d-sm-inline">{{ $t("menus.dashboard") }}</span>
          </a>
        </li>
        
        <li class="nav-item"> 
          <a 
            href="javascript:void(0)" 
            v-on:click="$router.push('/apps')" 
            :class="['nav-link px-0 align-middle', isActive('/apps')]"
          >
            <i class="fs-4 bi-table"></i> 
            <span class="ms-1 d-none d-sm-inline">{{ $t("menus.apps") }}</span>
          </a>
        </li>
        
        <li class="nav-item"> 
          <a 
            href="javascript:void(0)" 
            v-on:click="$router.push('/users')" 
            :class="['nav-link px-0 align-middle', isActive('/users')]"
          >
            <i class="fs-4 bi-people"></i> 
            <span class="ms-1 d-none d-sm-inline">{{ $t("menus.users") }}</span>
          </a>
        </li>
  
        <!-- Content Admin Submenu -->
        <li class="nav-item">
          <a href="javascript:void(0)" @click="toggleContentAdmin" class="nav-link px-0 align-middle">
            <i class="fs-4 bi-file-earmark-text"></i> 
            <span class="ms-1 d-none d-sm-inline">{{ $t("menus.content_admin") }}</span>
          </a>
          <ul class="nav flex-column ms-1"
              :class="{ 'collapse': true, 'show': contentAdminOpen }"
              id="submenu3"
              data-bs-parent="#menu">
            <li class="w-100">
              <a 
                href="javascript:void(0)" 
                v-on:click="$router.push('/pages')" 
                :class="['nav-link px-0', isActive('/pages')]"
              >
                <span class="d-none d-sm-inline">{{ $t("menus.pages") }}</span>
              </a>
            </li>
            <li>
              <a 
                href="javascript:void(0)" 
                v-on:click="$router.push('/questionnaire')" 
                :class="['nav-link px-0', isActive('/questionnaire')]"
              >
                <span class="d-none d-sm-inline">{{ $t("menus.questionnaire") }}</span>
              </a>
            </li>
            <li>
              <a 
                href="javascript:void(0)" 
                v-on:click="$router.push('/vitals')" 
                :class="['nav-link px-0', isActive('/vitals')]"
              >
                <span class="d-none d-sm-inline">{{ $t("menus.vitals") }}</span>
              </a>
            </li>
            <li>
              <a 
                href="javascript:void(0)" 
                v-on:click="$router.push('/faq')" 
                :class="['nav-link px-0', isActive('/faq')]"
              >
                <span class="d-none d-sm-inline">{{ $t("menus.faq") }}</span>
              </a>
            </li>
            <li>
              <a 
                href="javascript:void(0)" 
                v-on:click="$router.push('/trivia')" 
                :class="['nav-link px-0', isActive('/trivia')]"
              >
                <span class="d-none d-sm-inline">{{ $t("menus.trivia") }}</span>
              </a>
            </li>
          </ul>
        </li>
  
        <li class="nav-item">
          <a 
            href="javascript:void(0)" 
            v-on:click="$router.push('/setting')" 
            :class="['nav-link px-0 align-middle', isActive('/setting')]"
          >
            <i class="fs-4 bi-gear"></i> 
            <span class="ms-1 d-none d-sm-inline">{{ $t("menus.settings") }}</span>
          </a>
        </li>
      </ul>
  
      <div class="text-center pe-3 w-100 pb-3">
        <div class="bg-white bg-opacity-10 p-3">
          <img src="images/tech-spp-logo.svg" alt="">
          <p class="fs-5 mb-2 text-white"></p>
          <a href="javascript:void(0)" v-on:click="onLogout()" class="text-white text-decoration-none fs-6 font-light">
            <i class="bi bi-box-arrow-left"></i> {{ $t("menus.logout") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      admin: null,
      contentAdminOpen: false,
    };
  },
  mounted() {
    this.onGetMe();
    const savedState = localStorage.getItem("contentAdminOpen");
    this.contentAdminOpen = savedState === "true";
  },
  methods: {
    onGetMe() {
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "me",
        })
        .then((res) => {
          this.admin = res.info;
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onLogout() {
      var method = "POST";
      this.$api
        .webRequest({
          _method: method,
          _action: "logout",
        })
        .then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          localStorage.clear();
          this.$router.push("/login");
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
   
    toggleContentAdmin() {
      this.contentAdminOpen = !this.contentAdminOpen;
      console.log("Content Admin submenu toggled:", this.contentAdminOpen);
      localStorage.setItem("contentAdminOpen", this.contentAdminOpen);
    },
    isActive(route) {
      return this.$route.path === route ? "active-menu" : "";
    },
  },
};

</script>
   <!-- <script> -->
  <!-- // export default {
  //   name: "SideBar",
  //   data() {
  //     return {
  //       admin: null,
  //     };
  //   },
  //   mounted() {
  //     this.onGetMe();
  //   },
  //   methods: {
  //     onGetMe() {
  //       this.$api
  //         .webRequest({
  //           _method: "GET",
  //           _action: "me",
  //         })
  //         .then((res) => {
  //           this.admin = res.info;
  //         })
  //         .catch((e) => {
  //           this.$toast.error(e.message, { position: "top-right" });
  //         });
  //     },
  //     onLogout() {
  //       this.$api
  //         .webRequest({
  //           _method: "POST",
  //           _action: "logout",
  //         })
  //         .then((res) => {
  //           this.$toast.success(res.message, { position: "top-right" });
  //           localStorage.clear();
  //           this.$router.push("/login");
  //         })
  //         .catch((e) => {
  //           this.$toast.error(e.message, { position: "top-right" });
  //         });
  //     },
  //     isActive(route) {
  //       return this.$route.path === route ? "active-menu" : "";
  //     },
  //   },
  // };
  // </script> -->
  
  <style scoped>
 
  .active-menu {
    background-color: white !important;
    color: rgb(0, 0, 0) !important;
    font-weight: bold;
    border-radius: 8px;
  }
  
 
  .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transition: 0.4s;
  }
  </style>
  